/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import { Table, Form, Button, Spinner } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonDateTime from "../../Common/CommonDateTime";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import { Link, useParams, useNavigate  } from "react-router-dom";
import { AFTER_SALES_ORDERS_URL } from "../../Shared/constant";
import { GetafterSalesOrdersDetails, StartMainCommonLoader } from "../../../redux/actions/adminActions";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import { toast } from "react-toastify";
import moment from "moment";
import CommonLoader from "../../Common/CommonLoader";
import CommonewModel from "../../Common/CommonewModel";
import UploadFileAPI from "../../../Api/UploadFileAPI";

const AfterSalesOrdersDetails = (props) => {
    const { RouteName } = props;
    const { roomId } = useParams();
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, AfterSalesOrderReportDetails, OptionCourierList, AllboxList, commonMainLoader, ClearFormSet, accessToken } = useSelector((state) => state.adminReducers);
    const GetOnlyAccessoriesProduct = AfterSalesOrderReportDetails?.order_items_list;
    const [formData, setFormData] = useState({
        dispatch_date:"",
        distance:0,
        est_delivered_date:"",
        docket_number:"",
        courier_id:"",
        is_dispatch:"",
        docket_slip:"",
        order_id:roomId?.id,
        courier_sla:"",
    });
    const [ partialArray, setpartialArray ] = useState([]);
    const [ optionsBox, SetOptionsBox ] = useState([]);
    const [ optionsProduct, SetOptionsProduct ] = useState([]);
    const [ selectBox, setSelectBox ] = useState("");
    const totalQuantity = calculateTotalQuantity(GetOnlyAccessoriesProduct);
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];
    const exceptThisCostSymbolsNew = ["e", "E", "+", "-"];
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        subtitle: "",
        modalType: "",
        description: "",
        button: "",
        Data: {}
    });
    const today = new Date();

    useEffect(() => {
        if(AllboxList?.length > 0) {
            SetOptionsBox(()=>AllboxList?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.box_name })));
        };
    }, [ AllboxList ]);


    useEffect(() => {
        if(AfterSalesOrderReportDetails?.order?.order_status === "DISPATCHED") {
            setFormData({
                ...formData,
                docket_number: AfterSalesOrderReportDetails?.order?.docket_number === null ? "" : AfterSalesOrderReportDetails?.order?.docket_number,
                dispatch_date: new Date(AfterSalesOrderReportDetails?.order?.dispatch_date),
                est_delivered_date:"",
                docket_slip:"",
            });
        } else {
            setFormData({
                ...formData,
                docket_number: "",
                dispatch_date: "",
                est_delivered_date:"",
                docket_slip:"",
            });
        };
    }, [ AfterSalesOrderReportDetails?.order?.order_status ]);

    useEffect(() => {
        if(roomId) {
            let param = { 
                "transmit": "broadcast", 
                "url": "afs_order_detail",
                "request" : { 
                    "order_id" : roomId,
                    "is_weight" : true
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    }, [ roomId ]);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "courier_list",
            "request" : { 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : "",
                "status" : true,
                "order_by" :"-updated_at"
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "box_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get total balance and Credit Limit	
    const GetCurrentOrderRate = (current) => {
        const getCurrentFranchise = current?.order?.billing;
        const getCurrentFranchiseType = current?.franchise_type_detail;

        let creditlimit = 0;
        let wallet = 0
        if(getCurrentFranchiseType?.franchise_type_credit !== null && getCurrentFranchiseType?.franchise_type_credit !== "") {
            wallet = parseInt(getCurrentFranchiseType?.franchise_type_credit);
            creditlimit = parseInt(getCurrentFranchiseType?.franchise_type_credit);
        };
        if(getCurrentFranchise?.credit_addon !== null && getCurrentFranchise?.credit_addon !== "") {
            wallet = parseInt(getCurrentFranchise?.credit_addon) + wallet;
            creditlimit = parseInt(getCurrentFranchise?.credit_addon) + creditlimit;
        }; 
        if(getCurrentFranchise?.advance_amount !== null && getCurrentFranchise?.advance_amount !== "") {
            wallet = wallet + parseInt(getCurrentFranchise?.advance_amount);
        };

        return {
            Wallet_balance: (getCurrentFranchise?.advance_amount === null || getCurrentFranchise?.advance_amount === "") ? 0 :parseInt(getCurrentFranchise?.advance_amount),
            credit_limit: creditlimit,
            available_balance: wallet,
        };
    };

    const GetOrderStatus = (url) => {
        if(url === "DISPATCH_PENDING") {
            return "Pending Dispatch";
        } else if(url === "DISPATCHED") {
            return "Pending Delivery";
        } else if(url === "DELIVERED") {
            return "Delivered";
        } else if(url === "CANCELLED") {
            return "Cancelled";
        } else if(url === "RETURN") {
            return "Returned";
        } else if(url === "INVOICE_PENDING") {
            return "Invoice Pending"
        };
    };

    const callSubmitForm = (e, state) =>{
        e.preventDefault();

        const partialArrayset = partialArray?.map((elm) => {
            return {
                box_id: elm?.box_id?.id,
                parcel_id: elm?.parcel_id,
                box_weight: elm?.box_weight,
                items: elm?.items?.map((elms) => {
                    return{ order_item_id: elms?.order_item_id, qty: elms?.qty}
                }),
            }
        })
        
        if(formData?.courier_id ){
            Dispatch(StartMainCommonLoader({
                url: "afs_order_dispatch",
                loader: true
            }));
            let param = { 
                "transmit": "broadcast", 
                "url": "afs_order_dispatch",
                "request" : {
                    "order_id" : roomId,
                    "is_dispatch": state?true:false,
                    "is_reverse": false,
                    "courier_id":formData?.courier_id?.id,
                    "distance":  formData?.distance,
                    "est_delivered_date": moment(formData?.est_delivered_date).format("YYYY-MM-DD"),
                    "parcels": partialArrayset,
                },
                "DeviceId" : device_id
            };
            if(state){
                param.request.distance = formData?.distance
            }
            if(state && formData?.docket_number){
                param.request.docket_number = formData?.docket_number
            }
            if(state && formData?.est_delivered_date){
                param.request.est_delivered_date = moment(formData?.est_delivered_date).format("YYYY-MM-DD");
            }
            if(state && formData?.dispatch_date){
                param.request.dispatch_date = moment(formData?.dispatch_date).format("YYYY-MM-DD");
            }

            wsSend_request(websocket, param);
        }
    };


    const SubmitPendingDelivery = () => {
        Dispatch(StartMainCommonLoader({
            url: "afs_order_delivered",
            loader: true
        }));
        let param = { 
            "transmit": "broadcast", 
            "url": "afs_order_delivered",
            "request" : {
                "order_id" : roomId,
                "docket_number": formData?.docket_number,
                "dispatch_date": moment(formData?.dispatch_date).format("YYYY-MM-DD"),
                "delivered_date": moment(formData?.est_delivered_date).format("YYYY-MM-DD"),
                "docket_slip": formData?.docket_slip,
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    };

    const onChange = (e) => {
        if (e.target.name === "distance" && e.target.value !== "") {
            e.target.value = e.target.value.replace(/^0/g, "");
        }
       // Allow only numbers
        if (e.target.name === "docket_number") {
            if(parseInt(e.target.value)<=0){
                e.target.value = "";
            }
            e.target.value = e.target.value; // Remove non-numeric characters
        }
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value
        }));
    };

    const onChangeCourier_id = (value) => {
        setFormData((data) => ({
            ...data,
            courier_id: value,
            courier_sla: value?.sla
        }));
    };

    const onSelect = (e, name) =>{
        setFormData((data)=>({
            ...data,
            [name]:e
        }))
    };

    useEffect(() => {
        if(GetOnlyAccessoriesProduct !== undefined) {
            SetOptionsProduct(GetOnlyAccessoriesProduct?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.product_name })));
        };
    }, [ AfterSalesOrderReportDetails ]);

    const [ ItemAddState, setItemAddState ] = useState({
        product_id: "",
        Quantity: "",
    });
    const totalpartialArrayQuantity = calculateTotalQuantityMain(partialArray);

    // Function to calculate total quantity
    function calculateTotalQuantity(items) {
        if(items !== undefined) {
            let totalQuantity = 0;
            for (let i = 0; i < items.length; i++) {
                totalQuantity += items[i].qty;
            }
            return totalQuantity;
        }
    };

     // Function to calculate total quantity
     function calculateTotalQuantityMain(arrayOfObjects) {
        let totalQuantity = 0;

        // Iterate over the outer array
        arrayOfObjects.forEach(obj => {
            // Iterate over the inner array of each object
            obj.items.forEach(item => {
                // Add quantity of each item to totalQuantity
                totalQuantity += parseInt(item.qty);
            });
        });

        return totalQuantity;
    };

    const CheckTotalWieght = partialArray?.filter((elm) => elm?.box_weight === "")?.length;
    const CheckTotalProductArray = partialArray?.filter((elm) => elm?.items?.length === 0)?.length;

    const DisableBtn = () => {
        let status = true;
        const entyEqual = totalpartialArrayQuantity === totalQuantity;
        if(entyEqual && CheckTotalWieght === 0 ) {
            status = false;
        } else {
            status = true;
        };
        return status;
    };

    // Function to calculate total quantity
    function calculateTotalProductBox(productBoxArray) {
        let totalboxweight = 0;
        let totalQuantity = 0;
       
        // Iterate over the outer array
        productBoxArray.forEach(obj => {
           totalboxweight += parseFloat(obj?.product_id?.product?.box_weight);
           totalQuantity += parseInt(obj?.qty);
        });

        return totalboxweight * totalQuantity;
    };

    // Function to calculate total quantity
    function calculateProductTotalQuantity(arrayOfObjects) {
        let productQuantities = {};
        
        arrayOfObjects.forEach(obj => {
            // Iterate through the products array in each object
            obj.items.forEach(product => {
                const { order_item_id, qty } = product;
                // Check if the product ID already exists in the productQuantities object
                if (productQuantities[order_item_id]) {
                    // If it exists, add the quantity to the existing quantity
                    productQuantities[order_item_id] += qty;
                } else {
                    // If it doesn't exist, create a new entry with the quantity
                    productQuantities[order_item_id] = qty;
                }
            });
        });

        return productQuantities;
    };

    const BoxValueSelect = (value) => {
        if(value !== null) {
            setSelectBox(value);
        } else {
            setSelectBox("");
        }
    };

    const PartialArrayTotalQuantity = calculateProductTotalQuantity(partialArray);

    const AddProductItemFunction = (data) => {
        if(ItemAddState?.Quantity !== "" && ItemAddState?.product_id !== "") {
            const Checkqnty = optionsProduct?.filter((elm) => {
                if(ItemAddState?.product_id?.id === elm?.id) {
                    if(PartialArrayTotalQuantity[elm?.id] === undefined) {
                        return parseInt(ItemAddState?.Quantity) <= elm?.qty;    
                    } else {
                        return (parseInt(ItemAddState?.Quantity) + parseInt(PartialArrayTotalQuantity[elm?.id])) <= elm?.qty;
                    }
                }
            })

            if(Checkqnty?.length > 0) {
                const Objd = {
                    id: generateRandomId(),
                    order_item_id:  ItemAddState?.product_id?.id,
                    product_id: ItemAddState?.product_id,
                    qty: parseInt(ItemAddState?.Quantity),
                };
                addSubItemById(data?.parcel_id, Objd);
    
                setItemAddState({
                    ...ItemAddState,
                    product_id: "",
                    Quantity: "",
                });
            } else {
                return toast.error("The product quantity should not be greater than the actual product quantity.");
            }
        };
    };

    const partialtempArray = [...partialArray];
    const AddBoxFunction = () => {
        const obj = {
            parcel_id: generateRandomId(),
            box_id: selectBox,
            box_weight: "",
            items: []
        };
        partialtempArray?.push(obj);
        setpartialArray(partialtempArray);
        setSelectBox("");
    };

    const PartialArrayUpdate = [... partialArray];
    // update box weight total
    const UpdateBoxTotalWeight = (value, data_) => {
        const PartialArrayUpdateMain = PartialArrayUpdate?.map((elm) => {
            if(elm?.parcel_id === data_?.parcel_id) {
                return{
                    ...elm,
                    box_weight: value,
                }
            } else 
            return elm;
        });
        
        setpartialArray(PartialArrayUpdateMain);
    };

    // delete item
    const deleteItem = (elm, elm_item) => {
        const partialArraysss = partialArray.map((obj) => {
            if(elm?.parcel_id === elm?.parcel_id) {
                return({
                    ...obj,
                    items: obj.items.filter(item => item.id !== elm_item?.id)
                })
            };
        });
        setpartialArray(partialArraysss);
    };

    const [ optionsProductNew, setoptionsProductNew ] = useState([]);

    useEffect(() => {
        setoptionsProductNew(() => optionsProduct?.filter((elm) => {
            if(PartialArrayTotalQuantity[elm?.id] !== parseInt(elm?.qty)) {
                return elm;
            };
        }))
    }, [ optionsProduct, partialArray ]);

    function generateRandomId() {
        // Generate a random number
        const randomNumber = Math.floor(Math.random() * 10000);
        
        // Get the current timestamp
        const timestamp = new Date().getTime();
        
        // Concatenate the random number and timestamp to create the ID
        const randomId = `${timestamp}-${randomNumber}`;
        
        return randomId;
    };

    // product selection
    const ProductSelection = (value) => {
        if(value !== null) {
            setItemAddState({...ItemAddState, product_id: value});
        } else {
            setItemAddState({...ItemAddState, product_id: ""});
        };
    };

    let updatedData = [...partialArray];
    // Function to add a new subItem to an item by ID
    const addSubItemById = (id, newSubItem) => {
        const itemIndex = partialArray.findIndex(item => item.parcel_id === id);
        if (itemIndex !== -1) {
            updatedData[itemIndex].items.push(newSubItem);
            let mergedArray = mergeAndUpdate(updatedData, id);
            const updatedDataArray = updatedData?.map((elm) => {
                if(elm?.parcel_id === id) {
                    return{
                        ...elm,
                        items: mergedArray,
                    }
                } else {
                    return elm;
                };
            });
            
            setpartialArray(updatedDataArray);
        } else {
            console.log('Item not found with id:', id);
        }
    };

    // merge array and add quantity
    function mergeAndUpdate(arr, id) {
        let updatedItems = {};
        arr.forEach(obj => {
            if(obj?.parcel_id === id) {
                obj.items.forEach(item => {
                    const productId = item.order_item_id;
                    if (!updatedItems[productId]) {
                        updatedItems[productId] = { ...item };
                    } else {
                        updatedItems[productId].qty += parseInt(item.qty);
                    }
                });
            };
        });
       
        let updatedData = Object.keys(updatedItems).map(productId => {
            return {
                id: updatedItems[productId].id,
                order_item_id: productId,
                qty: updatedItems[productId].qty,
                product_id: updatedItems[productId].product_id
            };
        });
        
        return updatedData;
    };

    const UploadImage = async (e) => {
        const files = e.target.files[0];
        if(files){
            const formData = new FormData();
            formData.append("file", files, files.name);
            const fileresponse = await UploadFileAPI(accessToken, formData);
            if(fileresponse?.status === 200) {
                // setSubCategoryState({...subCategoryState, ProductImage: fileresponse?.id});
                setFormData((data)=>({
                    ...data,
                    [e.target.name]:fileresponse?.id,
                    fileName: files.name,
                }))
            } else {
                // setUploadFileLoader(false);
            };
        } else{
            setFormData((data)=>({
                ...data,
                [e.target.name]:"",
                fileName: "",
            }))
        }
    };

    // order cancel function
    const callCancelOrder = (url) => {
        const ModalTypes = url === "cancel_order" ? "AFS-Order-Cancel" : "AFS-Order-Return";
        const order_types = url === "cancel_order" ? "cancel" : "return";
        setShowModalNew({
            ...showModalNew,
            open: true,
            title: "Confirmation",
            subtitle: ModalTypes,
            modalType: ModalTypes,
            description: `Are you sure to proceed for ${order_types} order?`,
            modalType: "not form",
            button: "Yes",
            Data: AfterSalesOrderReportDetails?.order
        });
    };

    // create new box modal
    const AddNewBoxModal = () => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Create New Box",
            subtitle: "Create New Box",
            modalType: "form",
            button: "Create"
        })
    };

    useEffect(() => {
        if ((ClearFormSet?.url === "afs_order_dispatch" || ClearFormSet?.url === "afs_order_cancelled_reject_return" || ClearFormSet?.url === "afs_order_delivered") && ClearFormSet?.action === 200) {
            Navigate(AFTER_SALES_ORDERS_URL);
        };
    }, [ClearFormSet]);

    // check dispatch date is current or greater than current date
    const givenDispatchDateCheck = () => {
        const givenDispatchDate = new Date(AfterSalesOrderReportDetails?.order?.dispatch_date);
        const currentDate = new Date();
    
        givenDispatchDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
    
        // Check if the given date is less than or equal to the current date
        if (givenDispatchDate <= currentDate) {
            return true;
        } else {
            return false;
        };
    };


    return(<section className="Main_section">
        {AfterSalesOrderReportDetails !== undefined && Object.keys(AfterSalesOrderReportDetails)?.length > 0 ? (<>
            <div className="MainHeader AfterSalesHeaderSteps">
                <h4><i className="bi bi-backspace-reverse-fill"></i> {GetOrderStatus(AfterSalesOrderReportDetails?.order?.order_status)}</h4>
                <Link to={AFTER_SALES_ORDERS_URL} onClick={() => Dispatch(GetafterSalesOrdersDetails({}))} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
            </div>
            <Scrollbars 
                style={{ height: (AfterSalesOrderReportDetails?.order?.order_type === "AO" || AfterSalesOrderReportDetails?.order?.order_type === "FO") ? "calc(100vh - 145px)" : "calc(100vh - 249px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
            <div className="filtr_Form"  style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
                <div className="formloginset formloginsetStaticData">
                    <div className="row">
                        {AfterSalesOrderReportDetails?.order?.order_type !== "CO" && (<div className="col-md-3" >
                            <Form.Group className="mb-3 ">
                                <Form.Label>Franchise Name</Form.Label>
                                <div className="defaultvalueField">
                                {AfterSalesOrderReportDetails?.order?.customer_name}
                                </div>
                            </Form.Group>
                        </div>)}
                        <div className="col-md-3">
                            <Form.Group className="mb-3 ">
                                <Form.Label>Request No</Form.Label>
                                <div className="defaultvalueField">
                                    {AfterSalesOrderReportDetails?.order?.order_code}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3" >
                            <Form.Group className="mb-3 ">
                                <Form.Label>Request Date</Form.Label>
                                <div className="defaultvalueField">
                                    {CommonDateTime(AfterSalesOrderReportDetails?.order?.created_at, "LL", "show")}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3" >
                            <Form.Group className="mb-3 ">
                                <Form.Label>Total Qty</Form.Label>
                                <div className="defaultvalueField">
                                {AfterSalesOrderReportDetails?.order?.qty}
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3" >
                            <Form.Group>
                                <Form.Label>State:</Form.Label>
                                <div className="defaultvalueField">
                                {AfterSalesOrderReportDetails?.order?.state}
                                </div>
                            </Form.Group>
                        </div>
                        {AfterSalesOrderReportDetails?.order?.order_type === "FO" && (<>
                            <div className="col-md-3" >
                                <Form.Group>
                                    <Form.Label>Wallet Balance:</Form.Label>
                                    <div className="defaultvalueField">
                                    {AmountNumberFormat(GetCurrentOrderRate(AfterSalesOrderReportDetails)?.Wallet_balance)}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-3" >
                                <Form.Group>
                                    <Form.Label>Credit Limit:</Form.Label>
                                    <div className="defaultvalueField">
                                    {AmountNumberFormat(GetCurrentOrderRate(AfterSalesOrderReportDetails)?.credit_limit)}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-3" >
                                <Form.Group>
                                    <Form.Label>Available Balance:</Form.Label>
                                    <div className="defaultvalueField">
                                    {AmountNumberFormat(GetCurrentOrderRate(AfterSalesOrderReportDetails)?.available_balance)}
                                    </div>
                                </Form.Group>
                            </div>
                        </>)}
                    </div>
                </div>
            </div>
            <div className="tableView gstreporttableadded">
                <Table id="stock_level_so_approval" bordered  >
                    <thead>
                        <tr>
                            <th>SNo</th>
                            <th>Code</th>
                            <th>Product Name</th>
                            <th>Amount</th>
                            <th>Unit Amount</th>
                            <th>Tax Amount</th>
                            <th>Total Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        {AfterSalesOrderReportDetails?.order_items_list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>{index + 1}</td>
                                <td>{elm?.product_code}</td>
                                <td>{elm?.product_name}</td>
                                <td>{elm?.sub_total}</td>
                                <td>{parseInt(elm?.price).toFixed(2)}</td>
                                <td>{elm?.sub_gst}</td>
                                <td>{elm?.qty}</td>
                            </tr>)
                        })}
                        {(AfterSalesOrderReportDetails?.order?.order_status === "INVOICE_PENDING" || AfterSalesOrderReportDetails?.order?.order_status === "OPEN") ? (<tr>
                            <td colSpan={10} style={{ textAlign:"right" }}>
                                <Button 
                                    className={`btn button-1 w-auto btn-sm`} 
                                    onClick={() => callCancelOrder("cancel_order")}
                                >
                                    Cancel Order
                                </Button>
                            </td>
                        </tr>) : AfterSalesOrderReportDetails?.order?.order_status === "DISPATCH_PENDING" ? (<tr>
                            <td colSpan={10}>
                                <div className="FranchiseDispatchBox">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3" >
                                                <Form.Label style={{ width: "100%" }}>
                                                    <b>Select Box</b>
                                                    <span className="selectBoxAnchor" onClick={() => AddNewBoxModal()}>Add New Box</span>
                                                </Form.Label>
                                                <div className="selectMultiselect" id="selectMultiselectBrand">
                                                    <Select
                                                        value={selectBox}
                                                        onChange={(e) => BoxValueSelect(e)} 
                                                        isClearable 
                                                        options={optionsBox}
                                                        className="godown-select-container" 
                                                        classNamePrefix="godown-select"
                                                        styles={customStyles}
                                                        placeholder="Select Box"
                                                        menuPlacement="auto"
                                                        menuPortalTarget={document.body}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3" >
                                                <Form.Label style={{ width: "100%"}}>&nbsp;</Form.Label>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <button 
                                                        className="btn addBoxbtns" 
                                                        onClick={() => AddBoxFunction()} 
                                                        disabled={(selectBox === "" || totalQuantity === totalpartialArrayQuantity) || CheckTotalProductArray > 0} 
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    {partialArray && partialArray?.map((elm, index) => {
                                        return(<div className="formloginset" key={index}>
                                            <div className="HeaderDivider" style={{ paddingTop: "40px" }}>
                                                <h6 className="subtitleForm PurchaseOrderCheckbox">
                                                    Box Name: {elm?.box_id?.box_name}
                                                </h6>
                                                {totalQuantity !== totalpartialArrayQuantity && (<div className="row">
                                                    <div className="col-md-4">
                                                        <Form.Group className="mb-3 " >
                                                            <Form.Label>Product</Form.Label>
                                                            <div className="selectMultiselect selectMultiselectCategory" id="selectMultiselectBrand">
                                                                <Select 
                                                                    onChange={(e) => ProductSelection(e)} 
                                                                    value={ItemAddState?.product_id}
                                                                    isClearable
                                                                    options={optionsProductNew}
                                                                    className="godown-select-container" 
                                                                    classNamePrefix="godown-select"
                                                                    placeholder="Select Product"
                                                                    styles={customStyles}
                                                                    menuPlacement="auto"
                                                                    menuPortalTarget={document.body}
                                                                />
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Form.Group className="mb-3 " >
                                                            <Form.Label>Quantity</Form.Label>
                                                            <Form.Control 
                                                                type="number" 
                                                                className="inputBoxs"
                                                                value={ItemAddState?.Quantity} 
                                                                onChange={(e) => {
                                                                    setItemAddState({...ItemAddState, Quantity: e.target.value.replace(/^0/, "")})
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    EmptySpaceFieldValid(e)
                                                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                                                }}
                                                                placeholder="Enter Quantity" 
                                                                maxLength={2}
                                                                max={2}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label style={{ width: "100%"}}>&nbsp;</Form.Label>
                                                            <button style={{ float: "left" }} className="btn addBoxbtns" disabled={ItemAddState?.Quantity === "" && ItemAddState?.product_id === ""} id="addBoxbtns"  onClick={() => AddProductItemFunction(elm)} >
                                                                Add
                                                            </button>
                                                        </Form.Group>
                                                    </div>
                                                </div>)}
                                                {elm?.items?.length > 0 && (<React.Fragment>
                                                    <Table className="printTable t-body-nowrap table-common mt" bordered >
                                                        <thead>
                                                            <tr>
                                                                <th>SNO.</th>
                                                                <th>Product Name</th>
                                                                <th>Box Weight</th>
                                                                <th>Quantity</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {elm?.items?.map((elm_, index) => {
                                                                return(<tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{elm_?.product_id?.product_name}</td>
                                                                    <td>{elm_?.product_id?.product?.box_weight}</td>
                                                                    <td>{elm_?.qty}</td>
                                                                    <td>
                                                                        <i className="bi bi-trash3 deleteItemBtn" onClick={() => deleteItem(elm, elm_)}></i>
                                                                    </td>
                                                                </tr>)
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </React.Fragment>)}
                                                <div className="tableforms">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <Form.Group className="mb-3 " >
                                                                <Form.Label>Total Weight</Form.Label>
                                                                <Form.Control 
                                                                    type="number" 
                                                                    className="inputBoxs"
                                                                    value={elm?.box_weight} 
                                                                    onChange={(e) => UpdateBoxTotalWeight(e?.target?.value.replace(/^0/, ""), elm)}
                                                                    onKeyDown={(e) => {
                                                                        EmptySpaceFieldValid(e)
                                                                        exceptThisCostSymbolsNew.includes(e.key) && e.preventDefault()
                                                                    }}
                                                                    placeholder="Enter Total" 
                                                                    maxLength={2}
                                                                    max={2}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <Form.Group className="mb-3 " >
                                                                <Form.Label>Estimate Total (kg)</Form.Label>
                                                                <Form.Control 
                                                                    type="number" 
                                                                    className="inputBoxs"
                                                                    value={calculateTotalProductBox(elm?.items)} 
                                                                    // onChange={(e) => UpdateBoxTotalWeight(e?.target?.value, elm)}
                                                                    // onKeyDown={(e) => {
                                                                    //     EmptySpaceFieldValid(e)
                                                                    //     exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                                                    // }}
                                                                    placeholder="Estimate Total" 
                                                                    maxLength={2}
                                                                    max={2}
                                                                    disabled={true}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    })}
                                </div>
                                <Table className="printTable t-body-nowrap table-common m-0 dispatchTbale_ui" bordered >
                                    <tbody>
                                        <tr >
                                            <th style={{width:"20%"}}>Courier</th>
                                            <td className="carrier min">
                                                <div className="selectMultiselect col-sm-6 col-lg-4 selectMultiselectCategory" id="selectMultiselectBrand">
                                                    <Select 
                                                        value={formData?.courier_id}
                                                        onChange={onChangeCourier_id} 
                                                        name={"courier_id"}
                                                        options={OptionCourierList?.data}
                                                        className="godown-select-container" 
                                                        classNamePrefix="godown-select"
                                                        styles={customStyles}
                                                        placeholder="Select"
                                                        menuPlacement="auto"
                                                        menuPortalTarget={document.body}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr >
                                            <th style={{width:"20%"}} >Docket Number</th>
                                            <td>
                                                <div className="col-sm-6 col-lg-4">
                                                    <Form.Control 
                                                        name={"docket_number"}
                                                        onChange={onChange}
                                                        value={formData?.docket_number}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{width:"20%"}} >Dispatch Date</th>
                                            <td >
                                                <div className="datepickField min col-sm-6 col-lg-4">
                                                    <ReactDatePicker
                                                        selected={formData?.dispatch_date} 
                                                        startDate={formData?.dispatch_date}
                                                        onChange={(e)=>onSelect(e, "dispatch_date")} 
                                                        // minDate={subDate(new Date(), 34)}
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth 
                                                        showMonthDropdown
                                                        name="dispatch_date"
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText="Enter From Date"
                                                        isClearable={formData?.dispatch_date !== "" ? true : false}
                                                        // maxDate={subDate(new Date(OrderReportDetails?.order?.invoice_at), -1)}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr >
                                            <th style={{width:"20%"}}>Estimated Delivered Date</th>
                                            <td>
                                                <div className="datepickField min col-sm-6 col-lg-4">
                                                    <ReactDatePicker
                                                        selected={formData?.est_delivered_date} 
                                                        startDate={formData?.est_delivered_date}
                                                        minDate={new Date(formData?.dispatch_date)}
                                                        // minDate={new Date()}
                                                        onChange={(e)=>onSelect(e, "est_delivered_date")} 
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth 
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        name="est_delivered_date"
                                                        dropdownMode="select"
                                                        placeholderText="Enter From Date"
                                                        isClearable={formData?.est_delivered_date !== "" ? true : false}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        {(formData?.courier_sla && formData?.dispatch_date) && (<tr>
                                            <th style={{width:"20%"}}>Promise Date</th>
                                            <td>{GetDispachDate()}</td>
                                        </tr>)}
                                        <tr >
                                            <th style={{width:"20%"}} >Distance</th>
                                            <td>
                                                <div className="col-sm-6 col-lg-4">
                                                    <Form.Control 
                                                        name={"distance"}
                                                        onChange={onChange}
                                                        className="inputBoxs"
                                                        value={formData?.distance}
                                                        type="number"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div className="text-center mb-2 mt-3">
                                    <Button 
                                        disabled={
                                            formData?.courier_id === "" || 
                                            (formData?.est_delivered_date === "" || formData?.est_delivered_date === null) || 
                                            AfterSalesOrderReportDetails?.order?.order_status === "HALF_DISPATCH" || 
                                            DisableBtn() ? true : false
                                        } 
                                        className="btn exportbtn" 
                                        variant={"exportbtn"} 
                                        onClick={callSubmitForm}
                                    >
                                        Eship Dispatch
                                    </Button>
                                    <Button 
                                        disabled={
                                            ((formData?.est_delivered_date &&
                                            formData?.dispatch_date &&
                                            formData?.distance!="" &&
                                            formData?.courier_id && 
                                            formData?.docket_number) && !DisableBtn()) ? false:true
                                        } 
                                        className="btn exportbtn" 
                                        variant={"exportbtn"} 
                                        onClick={(e)=>callSubmitForm(e, true)}
                                    >
                                        Manual Dispatch
                                    </Button>
                                    <Button 
                                        className={`btn button-1 w-auto btn-sm`} 
                                        onClick={() => callCancelOrder("cancel_order")}
                                    >
                                        Cancel Order
                                    </Button>
                                </div>
                            </td>
                        </tr>) : AfterSalesOrderReportDetails?.order?.order_status === "DISPATCHED" ? (<tr>
                            <td colSpan={10}>
                                <div className="FranchiseDispatchBox" style={{ position: "relative" }}>
                                {!givenDispatchDateCheck() && (<div className="order_warning_error">
                                    <i className="bi bi-ban"></i>
                                    The dispatch date must be the current date or earlier; future dates are not allowed for pending deliveries.
                                </div>)}
                                <Table  className="printTable t-body-nowrap table-common m-0 dispatchTbale_ui" bordered >
                                    <tbody>
                                        <tr >
                                            <th style={{width:"20%"}} >Docket Number</th>
                                            <td>
                                                <div className="col-sm-6 col-lg-4">
                                                    <Form.Control 
                                                        name={"docket_number"}
                                                        onChange={onChange}
                                                        value={formData?.docket_number}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{width:"20%"}} >Dispatch Date</th>
                                            <td >
                                                <div className="datepickField min col-sm-6 col-lg-4">
                                                    <ReactDatePicker
                                                        selected={formData?.dispatch_date} 
                                                        startDate={formData?.dispatch_date}
                                                        onChange={(e)=>onSelect(e, "dispatch_date")} 
                                                        // minDate={subDate(new Date(), 34)}
                                                        maxDate={today}
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth 
                                                        showMonthDropdown
                                                        name="dispatch_date"
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText="Enter Dispatch Date"
                                                        isClearable={formData?.dispatch_date !== "" ? true : false}
                                                        // maxDate={subDate(new Date(OrderReportDetails?.order?.invoice_at), -1)}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr >
                                            <th style={{width:"20%"}}>Actual Delivered Date</th>
                                            <td>
                                                <div className="datepickField min col-sm-6 col-lg-4">
                                                    <ReactDatePicker
                                                        selected={formData?.est_delivered_date} 
                                                        startDate={formData?.est_delivered_date}
                                                        minDate={new Date(formData?.dispatch_date)}
                                                        // minDate={new Date()}
                                                        maxDate={today}
                                                        onChange={(e)=>onSelect(e, "est_delivered_date")} 
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth 
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        name="est_delivered_date"
                                                        dropdownMode="select"
                                                        placeholderText="Enter Actual Delivered Date"
                                                        isClearable={formData?.est_delivered_date !== "" ? true : false}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr >
                                            <th style={{width:"20%"}}>Docket Slip</th>
                                            <td>
                                                   {formData?.docket_slip ?(
                                                        <div className="uplaodedFilename">
                                                            <i className="bi bi-file-earmark-text"></i>
                                                            {formData?.fileName}
                                                            <i className="bi bi-x-circle-fill" onClick={() => setFormData({...formData, docket_slip:""})}></i>
                                                        </div>
                                                   ):(
                                                        <label id="UploadCmnBTN" className="btn head_cmn_btn UploadCmnBTN" style={{ width: "auto", marginTop: "0px", float: "left" }}>
                                                            <i className="bi bi-upload"></i>
                                                            <input type="file" name={"docket_slip"} id="fileInput" onChange={UploadImage} accept=".png, .jpg, .pdf" />
                                                            Upload Slip
                                                        </label>
                                                   )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div className="text-center mb-2 mt-3">
                                    <Button 
                                        disabled={
                                            (formData?.docket_number === "" || 
                                             formData?.dispatch_date === "" || 
                                             formData?.est_delivered_date === "" || 
                                             formData?.docket_slip === ""
                                            ) ? true : false
                                        } 
                                        className="btn exportbtn" 
                                        variant={"exportbtn"} 
                                        onClick={SubmitPendingDelivery}
                                    >
                                        Update
                                    </Button>
                                    <Button 
                                        className={`btn button-1 w-auto btn-sm`} 
                                        onClick={() => callCancelOrder("return_order")}
                                    >
                                        Return Order
                                    </Button>
                                </div>
                            </div>
                        </td>
                    </tr>) : ("")}
                    </tbody>
                </Table>
            </div>
            </Scrollbars>
        </>) : (<>
            Please Wait...
        </>)}

        {showModalNew?.open && (
            <CommonewModel 
                showModalNew={showModalNew} 
                setShowModalNew={setShowModalNew} 
            />
        )}

        {((commonMainLoader?.url === "afs_order_dispatch" || commonMainLoader?.url === "afs_order_cancelled_reject_return" || commonMainLoader?.url === "afs_order_delivered") && commonMainLoader?.loader) && (<CommonLoader />)}
    </section>)
}

export default AfterSalesOrdersDetails;