/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import { Table, Badge } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import { getTableSorting, updateAFSOrderManagementFilter } from "../../../redux/actions/adminActions";
import { Link } from "react-router-dom";
import { TimeSense } from "../../Common/TimeSense";
import { AFTER_SALES_ORDERS_URL } from "../../Shared/constant";
import AFSOrderDataTableFilter from "../../Common/AFSOrderDataTableFilter";
import CommonLogout from "../../Common/CommonLogout";
import OrderDetailsViewer from "./FranchiseDispatchEdit";

const AfterSalesPendingDeliveryOrders = (props) => {
    const { OrderNewModel, setOrderNewModel, tab_url } = props;
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, AfterSalesPendingDeliveryOrderList,selfInfo, AllDealerFranchiseTypeListAll, AFSOrderManagmentFilter, sortingfor, accessToken } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    const [OrderViewerModal, setOrderViewerModal] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });
    
    // page change
    const currentFunction = (page) => {
        Dispatch(updateAFSOrderManagementFilter({
            [tab_url]: {
                CurrentPage: page,
            }
        }));
    };

    useEffect(() => {
        CommonSortingFct("")
    }, []);

    // get order_list all
    useEffect(() => {
        const { UserLimit = 10, 
                UserSearch = '',
                SelectRegion = '', 
                FromDate = null,
                ToDate = null,
                CurrentPage = 1,
        } = AFSOrderManagmentFilter[tab_url] || {};

        let param = {
            "transmit": "broadcast",
            "url": "afs_order_list",
            "request": {
                "status": "DISPATCHED",
                "limit": UserLimit,
                "page_no": CurrentPage,
                "search": UserSearch.trim(),
                "from_date": null,
                "to_date": null,
                "from_inv": null,
                "to_inv": null,
                "region_id": SelectRegion,
                "order_by": sortingfor,
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    }, [ websocket, sortingfor, AFSOrderManagmentFilter[tab_url] ]);

    const GetFranchiseName = (billing_id) => {
        const current = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.id === billing_id)[0]
        return {
            name: current?.customer_name
        };
    };

    const CommonSortingFct = (url) => {
        if (sortingfor === url) {
            Dispatch(getTableSorting("-" + url))
        } else {
            Dispatch(getTableSorting(url))
        };
    };

    //order status
    const showOrder = (value) => {
        setOrderNewModel({
            ...OrderNewModel,
            open: !OrderNewModel?.open,
            title: "AFS Order Log",
            subtitle: "",
            Data: value
        });
    };

    // INVOICE DETAILS
    const callInvoiceModal = (e, code) => {
        e.preventDefault();
        let param = { 
            "transmit": "broadcast", 
            "url": "afs_order_detail",
            "request" : { 
                "order_id" : code,
                "is_weight" : true
            },
            "DeviceId" : device_id,
            modal: true
        };
        wsSend_request(websocket, param);
    };

    useEffect(() => {
        if(AFSOrderManagmentFilter === undefined) {
            CommonLogout(accessToken, Dispatch);
        }
    }, [ AFSOrderManagmentFilter ]);

    return (<section className="Main_section">
        <AFSOrderDataTableFilter 
            filterType={""}
            tabName={tab_url}
            searchType={"Order Code, Customer code, Customer name, Sales order code, Sales invoice code, Delivery note, Tickets id"}
            searchdisable={true}
            buttonvisible={false}
        />
        <div className="tableView gstreporttableadded">
            <Scrollbars
                style={{ height: AfterSalesPendingDeliveryOrderList?.pagination?.total_records > 10 ? "calc(100vh - 290px)" : "calc(100vh - 255px)" }}
                renderView={props => <div {...props} className="view" />}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNo</th>
                            <th className={sortingfor === "order_code" ? "active" : sortingfor === "-order_code" ? "inactive" : ""} onClick={() => CommonSortingFct("order_code")}>Order Id.</th>
                            <th className={sortingfor === "customer_identity" ? "active" : sortingfor === "-customer_identity" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_identity")}>Customer Code</th>
                            <th className={sortingfor === "created_at" ? "active" : sortingfor === "-created_at" ? "inactive" : ""} onClick={() => CommonSortingFct("created_at")}>Order Date</th>
                            <th className={sortingfor === "customer_name" ? "active" : sortingfor === "-customer_name" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_name")}>SHIP To Name</th>
                            <th className={sortingfor === "billing_id" ? "active" : sortingfor === "-billing_id" ? "inactive" : ""} onClick={() => CommonSortingFct("billing_id")}>BILL To Name</th>
                            <th>Ship From Warehouse</th>
                            <th className={sortingfor === "erp_invoice_code" ? "active" : sortingfor === "-erp_invoice_code" ? "inactive" : ""} onClick={() => CommonSortingFct("erp_invoice_code")}>Invoice No.</th>
                            <th className={sortingfor === "invoice_at" ? "active" : sortingfor === "-invoice_at" ? "inactive" : ""} onClick={() => CommonSortingFct("invoice_at")}>Invoice Date</th>
                            <th className={sortingfor === "courier_name" ? "active" : sortingfor === "-courier_name" ? "inactive" : ""} onClick={() => CommonSortingFct("courier_name")}>Transporter</th>
                            <th className={sortingfor === "delivered_date" ? "active" : sortingfor === "-delivered_date" ? "inactive" : ""} onClick={() => CommonSortingFct("delivered_date")}>Estimated Delivered Date</th>
                            <th className={sortingfor === "docket_number" ? "active" : sortingfor === "-docket_number" ? "inactive" : ""} onClick={() => CommonSortingFct("docket_number")}>Docket Number</th>
                            <th>ERP Sales Order Code</th>
                            <th>ERP Delivery Note Status</th>
                            <th>ERP Delivery Note</th>
                            <th>ERP Invoice Status</th>
                            <th>Tickets Id</th>
                            <th>Pending Since</th>
                        </tr>
                    </thead>
                    <tbody>
                        {AfterSalesPendingDeliveryOrderList?.list?.map((elm, index) => {
                            return (<tr key={index}>
                                <td>
                                    <Link className="btn-link btn-link-color text-decoration-none" onClick={() => showOrder(elm?.id)}>
                                        {GetCurrentTableSRNo(index, AfterSalesPendingDeliveryOrderList?.pagination?.current_page, AfterSalesPendingDeliveryOrderList?.pagination?.record_limit)}
                                    </Link>
                                </td>
                                <td>
                                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[18]?.Edit) ? (<Link className="btn-link btn-link-color text-decoration-none" to={`${AFTER_SALES_ORDERS_URL}/${elm?.id}`}>
                                        <b className="tableBolanchor">{elm?.order_code}</b>
                                    </Link>) : (<b className="tableBolanchor">{elm?.order_code}</b>)}
                                </td>
                                <td>
                                    {elm?.customer_identity === null ? "-" : elm?.customer_identity}
                                </td>
                                <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                                <td>{elm?.customer_name !== null ? elm?.customer_name : "-"}</td>
                                <td>{GetFranchiseName(elm?.billing_id)?.name === undefined ? "-" : GetFranchiseName(elm?.billing_id)?.name}</td>
                                <td>{elm?.warehouse_name === null ? "-" : elm?.warehouse_name}</td>
                                <td>
                                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[18]?.Edit) ? (
                                        <b className="tableBolanchor" onClick={(e) => callInvoiceModal(e, elm?.id)}>{elm?.erp_invoice_code}</b>
                                    ) : (
                                        <b className="tableBolanchor" style={{ cursor: "default" }} >{elm?.erp_invoice_code}</b>
                                    )}
                                </td>
                                <td>{CommonDateTime(elm?.invoice_at || elm?.updated_at, "LL", "show")}</td>
                                <td>{elm?.courier_name ? elm?.courier_name : "-"}</td>
                                <td>{CommonDateTime(elm?.est_delivered_date || elm?.est_delivered_date, "LL", "show")}</td>
                                <td>{elm?.docket_number === null ? "-" : elm?.shipment_url === null ? elm?.docket_number : (<a href={elm?.shipment_url} target="__blank">{elm?.docket_number ? elm?.docket_number : "-"}</a>)}</td>
                                <td>{elm?.erp_id === null ? "-" : elm?.erp_id}</td>
                                <td>{(elm?.erp_delivery_code === null || elm?.erp_delivery_code === "") ? (<Badge bg="danger">Failed</Badge>) : (<Badge bg="success">Success</Badge>)}</td>
                                <td>{(elm?.erp_delivery_code === null || elm?.erp_delivery_code === "") ? "-" : elm?.erp_delivery_code}</td>
                                <td>
                                    {(elm?.erp_invoice_code === null || elm?.erp_invoice_code === "") ? (<Badge bg="danger">Failed</Badge>) : (<Badge bg="success">Success</Badge>)}
                                </td>
                                <td>{elm?.ticket_id === null ? "-" : elm?.ticket_id}</td>
                                <td>{TimeSense(elm?.updated_at)}</td>
                            </tr>)
                        })}
                        {AfterSalesPendingDeliveryOrderList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="13">After sales pending delivery order list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {AfterSalesPendingDeliveryOrderList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={AFSOrderManagmentFilter[tab_url]?.CurrentPage}
                paginationLength={AfterSalesPendingDeliveryOrderList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>

        {OrderViewerModal?.open && (<OrderDetailsViewer 
            OrderViewerModal={OrderViewerModal}
            setOrderViewerModal={setOrderViewerModal}
        />)}
    </section>)
}

export default AfterSalesPendingDeliveryOrders;