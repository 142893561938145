/* eslint-disable */
import React, { useState } from "react";
import FbaOrderTab from "./FbaOrderTab";
import FbaOrderLogsTab from "./FbaOrderLogsTab";
import { Modal } from "react-bootstrap";

const FbaOrdersList = (props) => {
    const { RouteName } = props;
    const [ Tab, setTab ] = useState("orders");
    const [ OrderViewerModal, setOrderViewerModal ] = useState({
        open: false,
        Title: "",
        Data: {}
    });

    // error message modal close
    const CloseErrorMessageModal = () => {
        setOrderViewerModal({
            ...OrderViewerModal,
            open: false,
            Title: "",
            Data: {}
        });
    };

    return (<section className="Main_section">
        <div className="MainHeader">
            <ul className="MainHeader_Tab">
                <li className={Tab === "orders" ? "active" : ""} onClick={() => setTab("orders")}>Orders</li>
                <li className={Tab === "logs" ? "active" : ""} onClick={() => setTab("logs")}>Logs</li>
            </ul>
        </div>
        <React.Fragment>
            {Tab === "orders" ? (<>
                <FbaOrderTab />
            </>) : (<>
                <FbaOrderLogsTab OrderViewerModal={OrderViewerModal} setOrderViewerModal={setOrderViewerModal} />
            </>)}
        </React.Fragment>

        {OrderViewerModal?.open && (
            <Modal show={OrderViewerModal?.open} className="errorDetailsModal" onHide={()=> CloseErrorMessageModal()}>
                <Modal.Header closeButton>
                <Modal.Title>{OrderViewerModal?.Title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflow: "auto"}}>
                    <p>
                        {OrderViewerModal?.Data === undefined ? "No Error Found" : OrderViewerModal?.Data}
                    </p>
                </Modal.Body>
            </Modal>
        )}
    </section>)
}

export default FbaOrdersList;