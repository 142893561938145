/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import { Table, Badge, Button } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import { getTableSorting, updateAFSOrderManagementFilter } from "../../../redux/actions/adminActions";
import { Link } from "react-router-dom";
import { TimeSense } from "../../Common/TimeSense";
import { AFTER_SALES_ORDERS_URL } from "../../Shared/constant";
import AFSOrderDataTableFilter from "../../Common/AFSOrderDataTableFilter";
import CommonLogout from "../../Common/CommonLogout";
import ErpAfsRetryAPI from "../../../Api/ErpAfsRetryAPI";
import CommonLoader from "../../Common/CommonLoader";
import { toast } from "react-toastify";
import CommonewModel from "../../Common/CommonewModel";

const AfterSalesPendingInvoiceOrders = (props) => {
    const { OrderNewModel, setOrderNewModel, tab_url } = props;
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, AfterSalesOrderList,selfInfo, AllDealerFranchiseTypeListAll, AFSOrderManagmentFilter, sortingfor, accessToken } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    const [ Loader, setLoader ] = useState(false);
    const [showModalNew, setShowModalNew] = useState({
        open: false,
        title: "",
        subtitle: "",
        modalType: "",
        button: "",
        Data: {},
    });

    // page change
    const currentFunction = (page) => {
        Dispatch(updateAFSOrderManagementFilter({
            [tab_url]: {
                CurrentPage: page,
            }
        }));
    };

    useEffect(() => {
        CommonSortingFct("")
    }, []);

    // api calling
    const APICall = () => {
        const { UserLimit = 10, 
            UserSearch = '',
            SelectRegion = '', 
            FromDate = null,
            ToDate = null,
            CurrentPage = 1,
    } = AFSOrderManagmentFilter[tab_url] || {};
        let param = {
            "transmit": "broadcast",
            "url": "afs_order_list",
            "request": {
                "status": "OPEN,INVOICE_PENDING",
                "limit": UserLimit,
                "page_no": CurrentPage,
                "search": UserSearch.trim(),
                "from_date": null,
                "to_date": null,
                "from_inv": null,
                "to_inv": null,
                "region_id": SelectRegion,
                "order_by": sortingfor,
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        };
        wsSend_request(websocket, param);
    };

    // get order_list all
    useEffect(() => {
        APICall();
    }, [ websocket, sortingfor, AFSOrderManagmentFilter[tab_url] ]);

    const GetFranchiseName = (billing_id) => {
        const current = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.id === billing_id)[0]
        return {
            name: current?.customer_name
        };
    };

    const CommonSortingFct = (url) => {
        if (sortingfor === url) {
            Dispatch(getTableSorting("-" + url))
        } else {
            Dispatch(getTableSorting(url))
        };
    };

    // Retry ERP order
    const RtryFunction = async (data, url) => {        
        if(url === "invoice") {
            setShowModalNew({
                ...showModalNew,
                open: !showModalNew?.open,
                title: "Get ERP Invoice",
                subtitle: "Get ERP Invoice",
                modalType: "form",
                button: "Create",
                Data: data,
            })
        } else {
            CommonAPICall(data);
        };
    };

    // API CALLING FUCNTION
    const CommonAPICall = async (data) => {
        setLoader(true);
        const formData = new FormData();
        formData.append("order_id", data?.id);
        const response = await ErpAfsRetryAPI(formData, accessToken);
        if(response?.status === 200) {
            setLoader(false);
            APICall();
            toast.success(response?.message);
        } else {
            setLoader(false);
            APICall();
            if(response?.error) {
                toast.error(response?.error);
            } else {
                toast.error(response?.message);
            };
            if(response?.message === "User is not authorized." && response?.status === 400) {
                CommonLogout(accessToken, Dispatch);
                localStorage?.removeItem("access_token");
            };
        };
    };

    //order status
    const showOrder = (value) => {
        setOrderNewModel({
            ...OrderNewModel,
            open: !OrderNewModel?.open,
            title: "AFS Order Log",
            subtitle: "",
            Data: value
        });
    };

    useEffect(() => {
        if(AFSOrderManagmentFilter === undefined) {
            CommonLogout(accessToken, Dispatch);
        }
    }, [ AFSOrderManagmentFilter ]);

    return (<section className="Main_section">
        <AFSOrderDataTableFilter 
            filterType={""}
            tabName={tab_url}
            searchType={"Order Code, Customer code, Customer name, Sales order code, Sales invoice code, Delivery note, Tickets id"}
            searchdisable={true}
            buttonvisible={false}
        />
        <div className="tableView gstreporttableadded">
            <Scrollbars
                style={{ height: AfterSalesOrderList?.pagination?.total_records > 10 ? "calc(100vh - 290px)" : "calc(100vh - 255px)" }}
                renderView={props => <div {...props} className="view" />}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNo</th>
                            <th className={sortingfor === "order_code" ? "active" : sortingfor === "-order_code" ? "inactive" : ""} onClick={() => CommonSortingFct("order_code")}>Order Id.</th>
                            <th className={sortingfor === "customer_identity" ? "active" : sortingfor === "-customer_identity" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_identity")}>Customer Code</th>
                            <th className={sortingfor === "created_at" ? "active" : sortingfor === "-created_at" ? "inactive" : ""} onClick={() => CommonSortingFct("created_at")}>Order Date</th>
                            <th className={sortingfor === "customer_name" ? "active" : sortingfor === "-customer_name" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_name")}>SHIP To Name</th>
                            <th className={sortingfor === "billing_id" ? "active" : sortingfor === "-billing_id" ? "inactive" : ""} onClick={() => CommonSortingFct("billing_id")}>BILL To Name</th>
                            <th>Ship From Warehouse</th>
                            <th>ERP Sales Order Code</th>
                            <th>ERP Sales Order status</th>
                            <th>Pending Since</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[18]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {AfterSalesOrderList?.list?.map((elm, index) => {
                            return (<tr key={index}>
                                <td>
                                    <Link className="btn-link btn-link-color text-decoration-none" onClick={() => showOrder(elm?.id)}>
                                        {GetCurrentTableSRNo(index, AfterSalesOrderList?.pagination?.current_page, AfterSalesOrderList?.pagination?.record_limit)}
                                    </Link>
                                </td>
                                <td>
                                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[18]?.Edit) ? (<Link className="btn-link btn-link-color text-decoration-none" to={`${AFTER_SALES_ORDERS_URL}/${elm?.id}`}>
                                        <b className="tableBolanchor">{elm?.order_code}</b>
                                    </Link>) : (<b className="tableBolanchor">{elm?.order_code}</b>)}
                                </td>
                                <td>
                                    {elm?.customer_identity === null ? "-" : elm?.customer_identity}
                                </td>
                                <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                                <td>{elm?.customer_name !== null ? elm?.customer_name : "-"}</td>
                                <td>{GetFranchiseName(elm?.billing_id)?.name === undefined ? "-" : GetFranchiseName(elm?.billing_id)?.name}</td>
                                <td>{elm?.warehouse_name === null ? "-" : elm?.warehouse_name}</td>
                                <td>{elm?.erp_id === null ? "-" : elm?.erp_id}</td>
                                <td>
                                    {(elm?.erp_id === null || elm?.erp_id === "") ? (<Badge bg="danger">Failed</Badge>) : (<Badge bg="success">Success</Badge>)}
                                </td>
                                <td>{TimeSense(elm?.updated_at)}</td>
                                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[18]?.Edit) && (<td>
                                    <Button 
                                        className="btn edittable edittableretry btn btn-primary" 
                                        onClick={() => RtryFunction(elm, (elm?.erp_id === null || elm?.erp_id === "") ? "retry" : "invoice")}
                                    >
                                        {(elm?.erp_id === null || elm?.erp_id === "") ? (<>
                                            <i className="bi bi-arrow-repeat"></i> Retry
                                        </>) : (<>
                                            <i class="bi bi-receipt"></i> Get Invoice
                                        </>)}
                                    </Button>
                                </td>)}
                            </tr>)
                        })}
                        {AfterSalesOrderList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="13">After sales pending invoicing order list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {AfterSalesOrderList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={AFSOrderManagmentFilter[tab_url]?.CurrentPage}
                paginationLength={AfterSalesOrderList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>

        {Loader && (<CommonLoader />)}

        {/* COMMON MODAL FOR INVOICE CREATIONS */}
        {showModalNew?.open && (<CommonewModel 
            setShowModalNew={setShowModalNew} 
            showModalNew={showModalNew} />
        )}
    </section>)
}

export default AfterSalesPendingInvoiceOrders;