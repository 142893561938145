import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const generatePDF = (file_name, id, heading) => {
    const table = document.getElementById(id);

    html2canvas(table).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        const pdfWidth = pdf.internal.pageSize.getWidth(); // A4 width
        const pdfHeight = pdf.internal.pageSize.getHeight(); // A4 height

        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        // Scale the canvas dimensions to fit into the PDF dimensions
        const scaleFactor = Math.min(pdfWidth / imgWidth, (pdfHeight - 40) / imgHeight);

        const imgScaledWidth = imgWidth * scaleFactor;
        const imgScaledHeight = imgHeight * scaleFactor;

        // Add heading at the top
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(16);
        pdf.text(heading, pdfWidth / 2, 20, { align: "center" }); // Centered heading

        // Add the table image below the heading
        const tableYPosition = 30; // Position for table (below the heading)

        pdf.addImage(
            imgData,
            "PNG",
            (pdfWidth - imgScaledWidth) / 2, // Center the table horizontally
            tableYPosition,
            imgScaledWidth,
            imgScaledHeight
        );

        pdf.save(file_name + ".pdf");
    });
};

export default generatePDF;
