/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Button } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector,useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
// import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import ErpRetryAPI from "../../../Api/ErpRetryAPI";
import ErpAfsRetryAPI from "../../../Api/ErpAfsRetryAPI";
import { toast } from "react-toastify";
import CommonStatus from "../../Common/CommonStatus";
import CommonLogout from "../../Common/CommonLogout";

const ErpApiError = (props) => {
    const { OrderViewerModal, setOrderViewerModal, setLoader } = props;
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ IsSpares, setIsSpares ] = useState(false);
    const { websocket } = useContext(WebSocketContext);
    const { device_id, ErpApiFailureList, accessToken, selfInfo } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // useEffect(() => {
    //     if(ClearFormSet?.action === 200) {
    //         callFilterData()
    //         Dispatch(ClearFormSetFutios({
    //             url:"",
    //             action:false
    //         }));
    //     };
    // }, [ ClearFormSet ]);

    // api calling
    const APICall = () => {
        let param = {
            "transmit": "broadcast",
            "url": "erp_order_log_list",
            "request": {
                "limit": userLimit,
                "page_no": currentPage,
                "order_status":"FAILURE",
                "is_invoice": false,
                "is_spares": IsSpares,
                "search": userSearch.trim(),
                "order_by": "-updated_at"
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [websocket, userSearch, userType, currentPage, userLimit, IsSpares]);

    // Retry ERP order
    const RtryFunction = async (data) => {
        const GetCurrentERPList = ErpApiFailureList?.erp_order_log_list?.filter((elm) => elm?.order_id === data?.id)[0];
        setLoader(true);
        const formData = new FormData();
        formData.append("order_id", data?.id);
        const Response = GetCurrentERPList?.is_spares ? await ErpAfsRetryAPI(formData, accessToken) : await ErpRetryAPI(formData, accessToken);
        if(Response?.status === 200) {
            setLoader(false);
            APICall();
            toast.success(Response?.message);
        } else {
            setLoader(false);
            APICall();
            if(Response?.error) {
                toast.error(Response?.error);
            } else {
                toast.error(Response?.message);
            };
            if(Response?.message === "User is not authorized." && Response?.status === 400) {
                CommonLogout(accessToken, Dispatch);
                localStorage?.removeItem("access_token");
            };
        };
    };

    // error message modal open 
    const ErrorMessageShow = (data) => {
        const GetCurrentERPList = ErpApiFailureList?.erp_order_log_list?.filter((elm) => elm?.order_id === data?.id)[0];
        setOrderViewerModal({
            ...OrderViewerModal,
            open: open,
            Title: "Sales Order Error",
            Data: GetCurrentERPList,
        });
    };

    return(<section className="Main_section">
        <DataTableFilter 
            searchType={"Order Id."}
            filterType={"ERP_sales_order"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            IsSpares={IsSpares} 
            setIsSpares={setIsSpares}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: ErpApiFailureList?.pagination?.total_records > 10 ? "calc(100vh - 231px)" : "calc(100vh - 195px)" }} 
                renderView={props => <div {...props} className="view"/>}
                
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNo</th>
                            <th>Order Id</th>
                            <th>Order Date</th>
                            <th>Invoice ID</th>
                            <th>Invoice date</th>
                            <th>Order Source</th>
                            <th>Customer Code</th>
                            <th>Order Stage</th>
                            <th>Error</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[15]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {ErpApiFailureList?.list?.map((elm, index) => {
                            return(<tr key={index}>
                            <td>
                                {GetCurrentTableSRNo(index, ErpApiFailureList?.pagination?.current_page, ErpApiFailureList?.pagination?.record_limit)}
                            </td>
                            <td>{elm?.order_code}</td>
                            <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                            <td>{elm?.invoice_code === null ? "-" : elm?.invoice_code}</td>
                            <td>{CommonDateTime(elm?.invoice_at, "LL", "show")}</td>
                            <td>{elm?.order_source === null ? "-" : elm?.order_source}</td>
                            <td>{elm?.customer_identity === null ? "-" : elm?.customer_identity}</td>
                            <td>{CommonStatus(elm?.order_status)}</td>
                            <td>
                                <Button className="btn edittable btn btn-primary" onClick={()=> ErrorMessageShow(elm)}>
                                    <i className="bi bi-eye"></i>
                                </Button>
                            </td>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[15]?.Edit) && (<td>
                                <Button className="btn edittable edittableretry btn btn-primary" onClick={() => RtryFunction(elm)}>
                                    <i className="bi bi-arrow-repeat"></i> Retry
                                </Button>
                            </td>)}
                        </tr>)})}
                        {ErpApiFailureList?.list?.length === 0 && (<tr style={{ textAlign: "center" }} ><td colSpan="10">Sales order error list not found</td></tr>)}
                    </tbody>
                </Table>
            </Scrollbars>
            {ErpApiFailureList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={ErpApiFailureList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
    </section>)
}

export default ErpApiError;