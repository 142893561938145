/* eslint-disable */
import React, { useState } from "react";
import { Table, Button, Form, Spinner, Modal, Badge } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useSelector, useDispatch } from "react-redux";
import ImportDataAPI from "../../../Api/ImportDataAPI";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SampleFile from "../../../assets/file/franchise_orders.xlsx";
import { IMPORT_FO_ORDERS_API_URL } from "../../Shared/constant";
import CommonLogout from "../../Common/CommonLogout";
import CommonLoader from "../../Common/CommonLoader";

const BundlesOrders = (props) => {
    const { RouteName } = props;
    const Dispatch = useDispatch();
    const { accessToken, selfInfo } = useSelector((state) => state.adminReducers);
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        description: {},
        Data: {}
    });

    // bike store details state
    const [ LoaderStart, setLoaderStart ] = useState(false);
    const [ StockDetailsList, setStockDetailsList ] = useState({
        excluded_rows: [],
        included_rows: [],
    });
    const [ FileGetStore, setFileGetStore ] = useState({});
    const [ FileName, setFileName ] = useState("");
    const [ LoaderOrderAdd, setLoaderOrderAdd ] = useState(false);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // import file
    const ImportFile = async (e, status) => {
        setLoaderStart(true);
        if(e !== undefined) {
            const files = e?.target?.files[0];
            setFileGetStore(files);
            const formData = new FormData();
            formData.append("file", files, files.name);
            // formData.append("warehouse_id ", selectWarehouse?.value);
            formData.append("is_add", status);
            const fileresponse = await ImportDataAPI(IMPORT_FO_ORDERS_API_URL, accessToken, formData);
            if(fileresponse?.status === 200) {
                if(fileresponse?.data?.error) {
                    toast.error(fileresponse?.data?.error);
                } else {
                    toast.success(fileresponse?.message);
                    setFileName(files.name);
                    setStockDetailsList({
                        ...StockDetailsList,
                        excluded_rows: fileresponse?.data?.excluded_rows,
                        included_rows: fileresponse?.data?.included_rows,
                    });
                    handleClose();
                };
                setLoaderStart(false);
                document.getElementById('fileInput').value = null;
            } else {
                document.getElementById('fileInput').value = null;
                setLoaderStart(false);
                // setStockDetailsList({
                //     existing: [],
                //     new_added: [],
                //     new_existing: [],
                // });
                // setFileName("");
                setLoaderStart(false);
                toast.error(fileresponse?.message);
                if(fileresponse?.message === "User is not authorized." && fileresponse?.status === 400) {
                    CommonLogout(accessToken, Dispatch);
                    localStorage?.removeItem("access_token");
                };
            };
        } else {
            const files = FileGetStore;
            const formData = new FormData();
            formData.append("file", files, files.name);
            // formData.append("warehouse_id ", selectWarehouse?.value);
            formData.append("is_add", status);
            const fileresponse = await ImportDataAPI(IMPORT_FO_ORDERS_API_URL, accessToken, formData);
            if(fileresponse?.status === 200) {
                setLoaderOrderAdd(false);
                if(fileresponse?.data?.error) {
                    toast.error(fileresponse?.data?.error);
                } else {
                    toast.success(fileresponse?.message);
                    setFileName(files.name);
                    ResetDetails();
                    handleClose();
                };
                setLoaderStart(false);
                // document.getElementById('fileInput').value = null;
            } else {
                setLoaderOrderAdd(false);
                setLoaderStart(false);
                toast.error(fileresponse?.message);

                if(fileresponse?.message === "User is not authorized." && fileresponse?.status === 400) {
                    CommonLogout(accessToken, Dispatch);
                    localStorage?.removeItem("access_token");
                };
            };
        };
    };

    // add store details
    const AddDetails = (e) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
        });
    };

    const AddProductDetails = (e) => {
        setLoaderOrderAdd(true);
        ImportFile(e, 'yes');
    };

    // reset details
    const ResetDetails = () => {
        setStockDetailsList({
            excluded_rows: [],
            included_rows: [],
        });
        setFileGetStore({});
        setFileName("");
    };

    // close modal
    const handleClose = () => {
        setShowModalNew({...showModalNew, open: false});
    };

    return(<section className="Main_section">
        <div className="card-header-New" >
            <div className="filtr_Form" style={{ marginBottom: "0px" }}>
                <div className="formloginset">
                    <div className="row">
                        {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[1]?.Edit) && (<div className="col-md-3">
                            <Form.Group  >
                                {FileName === "" ? (<>
                                    <label id="UploadCmnBTN" className="btn head_cmn_btn UploadCmnBTN" style={{ width: "auto", marginTop: "0px", float: "left" }}>
                                        <i className="bi bi-upload"></i>
                                        <input type="file" id="fileInput" accept=".xlsx, .xls" onChange={(e) => ImportFile(e, 'no')} />
                                        Upload Sheet
                                    </label>
                                    {LoaderStart && (<div className="UploadLoader">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>)}
                                </>) : (<div className="uplaodedFilename">
                                    <i className="bi bi-filetype-xlsx"></i>
                                    {FileName}
                                    <i className="bi bi-x-circle-fill" onClick={() => ResetDetails()}></i>
                                </div>)}
                            </Form.Group>
                        </div>)}
                        <div className="col-md-3" style={{ width: "auto", float: "right", margin: "auto 0 auto auto" }}>
                            <Form.Group  >
                                {/* <Form.Label>&nbsp;</Form.Label> */}
                                <a href={SampleFile} className="btn exportbtn" style={{ width: "auto", marginTop: "0px" }}>
                                    <i className="bi bi-filetype-xlsx"></i>
                                    Download Format
                                </a>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="tableView">
            <Scrollbars 
                style={{ height: "calc(100vh - 198px)" }} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered   >
                    <thead>
                        <tr>
                            <th style={{ width: "10%" }}>SNO</th>
                            <th style={{ width: "14%" }}>Franchise Code</th>
                            <th style={{ width: "14%" }}>Bundle Id</th>
                            <th style={{ width: "14%" }}>Remarks</th>
                            <th>Products</th>
                        </tr>
                    </thead>
                    <tbody>
                        {StockDetailsList?.included_rows?.length === 0 ? (<tr>
                                <td colSpan={17} style={{ textAlign: "center" }}>Bundles orders not found</td>
                            </tr>) : StockDetailsList?.included_rows?.map((elm, index) => {
                            return(<React.Fragment key={index}>
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{elm[0]}</td>
                                    <td>{elm[1]}</td>
                                    <td>{elm[2] === "" ? "-" : elm[2]}</td>
                                    <td>
                                        <Table bordered   >
                                            <thead>
                                                <tr>
                                                    <th>ProductCode</th>
                                                    <th>Product Name</th>
                                                    {/* <th>Bundles Id</th> */}
                                                    <th>Qty</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {elm[3]?.map((elm, index) => {
                                                    return(<tr key={index}>
                                                        <td>{elm?.product_code}</td>
                                                        <td>{elm?.product_name}</td>
                                                        {/* <td>{elm?.parent_id}</td> */}
                                                        <td>{elm?.qty}</td>
                                                    </tr>)
                                                })}
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            </React.Fragment>)
                        })}
                    </tbody>
                </Table>
                {StockDetailsList?.excluded_rows?.length === 0 ? (<div className="btnGroupswraps" id="btnGroupswraps">
                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[1]?.Edit) && (<Button className="Addbtn" disabled={StockDetailsList?.included_rows?.length === 0} onClick={() => AddDetails()}>Add</Button>)}
                    <Button className="Resetbtn" onClick={() => ResetDetails()}>Reset</Button>
                </div>) : (<div className="btnGroupswraps error_note_bundle_wrapper" id="btnGroupswraps">
                    <h4 className="error_note_bundle"><i className="bi bi-x-circle-fill"></i>The file you uploaded contains some errors, which are displayed in the Order Rejected Table List. First, you need to click the reset button, go to the error file, resolve all the errors, and then upload the file again. Once the file is successfully uploaded, the add button will appear, allowing you to add the order.</h4>
                    <Button className="Resetbtn Resetbtnbundle" id="Resetbtnbundle" onClick={() => ResetDetails()}>Reset</Button>
                </div>)}

                {StockDetailsList?.excluded_rows?.length > 0 && (<div className="exsting_newdetails">
                    <h4 style={{ color: "red" }}>Order Rejected List.</h4>
                    <Table bordered   >
                        <thead>
                            <tr>
                                <th style={{ width: "10%" }}>SNO</th>
                                <th>Franchise Code</th>
                                <th>Bundle Id</th>
                                <th>Remarks</th>
                                <th>Error</th>
                            </tr>
                        </thead>
                        <tbody>
                            {StockDetailsList?.excluded_rows?.length === 0 ? (<tr>
                                    <td colSpan={17} style={{ textAlign: "center" }}>Bundles orders not found</td>
                                </tr>) : StockDetailsList?.excluded_rows?.map((elm, index) => {
                                return(<React.Fragment key={index}>
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{elm[0] === "" ? "-" : elm[0]}</td>
                                        <td>{elm[1] === "" ? "-" : elm[1]}</td>
                                        <td>{elm[2] === "" ? "-" : elm[2]}</td>
                                        <td><Badge bg="danger">{elm[3]}</Badge></td>
                                    </tr>
                                </React.Fragment>)
                            })}
                        </tbody>
                    </Table>
                </div>)}
            </Scrollbars>
        </div>
        
        {/* Add Fo order from ERP to our OMS order */}
        <Modal className="commonModal" show={showModalNew?.open} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title style={{ width: "100%"}}>
                    Are you sure?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="notformsection">
                    <p>Would you like to add the <b>{StockDetailsList?.included_rows?.length} order</b>?</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleClose()}>
                    No
                </Button>
                <Button variant="primary" className="addbtncmn" onClick={() => AddProductDetails()}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
        {LoaderOrderAdd && (<CommonLoader />)}
    </section>)
}

export default BundlesOrders;