/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Image, Collapse, Button } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import MoreIcon from '../../../assets/img/more_options.png';
import { getTableSorting } from "../../../redux/actions/adminActions";
import { toast } from "react-toastify";
import CommonLoader from "../../Common/CommonLoader";
import FBAorderLogRetryAPI from "../../../Api/FBAorderLogRetryAPI";
import CommonLogout from "../../Common/CommonLogout";
import GetRequest from "../../../Api/GetRequest";
import { FBA_ORDER_LOG_ERP_INVOICE_CREATE_API_URL } from "../../Shared/constant";

const FbaOrderLogsTab = (props) => {
    const { RouteName, OrderViewerModal, setOrderViewerModal } = props;
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [ Loader, setLoader ] = useState(false);
    const { device_id, TbaOrderLogsList, sortingfor, accessToken, selfInfo } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    useEffect(() => {
        Dispatch(getTableSorting(""))
    }, [])

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    const SOCancelled = () => {
        let param = {
            "transmit": "broadcast",
            "url": "fba_order_log_list",
            "request": {
                "limit": userLimit,
                "page_no": currentPage,
                "search": userSearch.trim(),
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    };

    // get order_list all
    useEffect(() => {
        if (websocket) {
            SOCancelled();
        }
    }, [websocket, userSearch, currentPage, userLimit, sortingfor]);

    // Retry ERP order
    const RtryFunction = async (id) => {
        setLoader(true);
        const formData = new FormData();
        formData.append("order_id", id);
        const response = await FBAorderLogRetryAPI(formData, accessToken);

        if(response?.status === 200) {
            setLoader(false);
            SOCancelled();
            toast.success(response?.message);
        } else {
            setLoader(false);
            SOCancelled();
            if(response?.error) {
                toast.error(response?.error);
            } else {
                toast.error(response?.message);
            };
            if(response?.message === "User is not authorized." && response?.status === 400) {
                CommonLogout(accessToken, Dispatch);
                localStorage?.removeItem("access_token");
            };
        };
    };

    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // error message modal open 
    const ErrorMessageShow = (data) => {
        const GetCurrentERPList = TbaOrderLogsList?.erp_invoice_log_list?.filter((elm) => elm?.order_id === data?.id)[0];
        setOrderViewerModal({
            ...OrderViewerModal,
            open: open,
            Title: "Fba Order Error",
            Data: GetCurrentERPList?.response,
        });
    };

    // fba order invoice create api 
    const FBAorderInvoiceCreateSync = async () => {
        setLoader(true);
        const response = await GetRequest(FBA_ORDER_LOG_ERP_INVOICE_CREATE_API_URL, accessToken);
        if(response?.status === 200) {
            setLoader(false);
            toast.success(response?.message);
        } else {
            setLoader(false);
            if(response?.error) {
                toast.error(response?.error);
            } else {
                toast.error(response?.message);
            };
            
            if(response?.message === "User is not authorized." && response?.status === 400) {
                CommonLogout(accessToken, Dispatch);
                localStorage?.removeItem("access_token");
            };
        };
    };

    return (<section className="Main_section fba_order_logs_Main_section">
        <DataTableFilter
            filterType={""}
            searchType={"Order Id"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
        />
        {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[16]?.Edit) && (<div className="sales_person_uplaod_downlo_wrapper">
            <button className="btn import_monthly_target" onClick={() => FBAorderInvoiceCreateSync()}>
                <i className="bi bi-arrow-repeat"></i> Create Sales Invoice
            </button>
        </div>)}
        <div className="tableView orderlistTable gstreporttableadded">
            <Scrollbars
                style={{ height: parseInt(TbaOrderLogsList?.pagination?.total_records) ===  TbaOrderLogsList?.list?.length ? "calc(100vh - 195px)" : TbaOrderLogsList?.pagination?.total_records > 10 ? "calc(100vh - 230px)" : "calc(100vh - 230px)" }}
                renderView={props => <div {...props} className="view" />}
                
                className="ScrollbarsSidebar "
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNo</th>
                            <th>Order Id</th>
                            <th>Channel Name</th>
                            <th>Order Date</th>
                            <th>SHIP To Pincode</th>
                            <th>SHIP To City</th>
                            <th>SHIP To State</th>
                            <th>Bill To Pincode</th>
                            <th>Bill To City</th>
                            <th>Bill To State</th>
                            <th>Marketplace Status</th>
                            <th>Error</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[16]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {TbaOrderLogsList?.list?.map((elm, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td style={{ width: '10px' }}>
                                            <button
                                                className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                                onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={activeTableDetails === elm?.id ? true : false}
                                            >
                                                <Image src={MoreIcon} alt="more icon" />
                                            </button>
                                        </td>
                                        <td>
                                            {GetCurrentTableSRNo(index, TbaOrderLogsList?.pagination?.current_page, TbaOrderLogsList?.pagination?.record_limit)}
                                        </td>
                                        <td>
                                            {elm?.display_order_code}
                                        </td>
                                        <td>{elm?.channel_name === null ? "-" : elm?.channel_name}</td>
                                        <td>{CommonDateTime(elm?.fba_created_at, "LL", "show")}</td>
                                        <td>{elm?.shipping_pincode !== null ? elm?.shipping_pincode : "-"}</td>
                                        <td>{elm?.shipping_city !== null ? elm?.shipping_city : "-"}</td>
                                        <td>{elm?.shipping_state !== null ? elm?.shipping_state : "-"}</td>
                                        <td>{elm?.bill_pincode !== null ? elm?.bill_pincode : "-"}</td>
                                        <td>{elm?.bill_city !== null ? elm?.bill_city : "-"}</td>
                                        <td>{elm?.bill_state !== null ? elm?.bill_state : "-"}</td>
                                        <td>{elm?.marketplace_status === null ? "-" : elm?.marketplace_status}</td>
                                        <td>
                                            <Button className="btn edittable btn btn-primary" onClick={()=> ErrorMessageShow(elm)}>
                                                <i className="bi bi-eye"></i>
                                            </Button>
                                        </td>
                                        {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[16]?.Edit) && (<td>
                                            <Button className="btn edittable edittableretry btn btn-primary" onClick={() => RtryFunction(elm?.id)}>
                                                <i className="bi bi-arrow-repeat"></i> Retry
                                            </Button>
                                        </td>)}
                                    </tr>
                                    <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                        <tr className="tablecollapsewraps" id={"tablecollpase" + elm?.id}>
                                            <td colSpan="13" >
                                                <Table bordered  className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>SHIP To Email</th>
                                                            <td>{(elm?.shipping_email !== null && elm?.shipping_email !== "") ? elm?.shipping_email : "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>SHIP To Name</th>
                                                            <td>{elm?.shipping_name !== null ? elm?.shipping_name : "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>SHIP To Phone</th>
                                                            <td>{elm?.shipping_phone !== null ? elm?.shipping_phone : "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Bill To Email</th>
                                                            <td>{(elm?.bill_email !== null && elm?.bill_email !== "") ? elm?.bill_email : "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Bill To Phone</th>
                                                            <td>{elm?.bill_phone !== null ? elm?.bill_phone : "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Order Source</th>
                                                            <td>{elm?.source === null ? "-" : elm?.source}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    </Collapse>
                                </React.Fragment>
                            )
                        })}
                        {TbaOrderLogsList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="12">FBA Order logs list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {TbaOrderLogsList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={currentPage}
                paginationLength={TbaOrderLogsList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>

        {Loader && (<CommonLoader />)}
    </section>)
}

export default FbaOrderLogsTab;