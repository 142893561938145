import React from "react";
import { AvatarColorCodes } from "../../AppModules";

const Avatar = (props) => {
    const { full_name } = props;
    const name = full_name?.split(" ");
    const GetNameFirstLetters = name[0].charAt(0).toLowerCase();
    let GetNameSecondLetters = "";

    if(name?.length > 1) {
        GetNameSecondLetters = name[1].charAt(0).toLowerCase()
    };

    return(<div 
        className="AvatarTitle"
        style={{
            backgroundColor: AvatarColorCodes[GetNameFirstLetters],
            textTransform: "uppercase"
        }}
    >
        {GetNameFirstLetters}
        {GetNameSecondLetters}
    </div>)
}

export default Avatar;